<template>
  <v-container>
    <v-app-bar dark app color="primary">
      <v-app-bar-nav-icon>
        <v-icon>mdi-account-settings</v-icon>
      </v-app-bar-nav-icon>
      <v-toolbar-title>Kürzel LK</v-toolbar-title>
      <v-spacer />
    </v-app-bar>

    <v-toolbar flat class="my-4">
      <v-text-field
        v-model="firstName"
        label="Vorname"
        @keydown.enter="generateCodes()"
      ></v-text-field>
      <v-spacer />
      <v-text-field
        v-model="lastName"
        label="Nachname"
        @keydown.enter="generateCodes()"
      ></v-text-field>
      <v-spacer />
      <v-btn text outlined @click="generateCodes()">kürzel finden</v-btn>
      <v-spacer />
      <v-divider vertical />
      <v-spacer />
      <v-text-field
        v-model="code"
        label="Kürzel"
        counter="3"
        :rules="rules"
        @keydown.enter="checkCode()"
        hint="Kürzel aus 3 Kleinbuchstaben"
        persistent-hint
      ></v-text-field>
      <v-spacer />
      <v-btn text outlined @click="checkCode()">kürzel testen</v-btn>
      <v-spacer />
      <v-btn text outlined @click="reset()">zurücksetzen</v-btn>
    </v-toolbar>
    <v-card class="mt-2">
      <v-card-text>
        <v-chip
          class="ma-4"
          v-for="item in items"
          :key="item.id"
          x-large
          color="error"
        >
          <v-avatar left size="56">
            <PortraitImage :value="item" />
          </v-avatar>
          <span>
            <PersonName :value="item" code />
          </span>
        </v-chip>
        <v-chip
          class="mr-4"
          v-for="code in codes"
          :key="code"
          x-large
          color="success"
        >
          <v-avatar left size="56">
            <v-icon x-large dark> mdi-account-circle </v-icon>
          </v-avatar>

          {{ code }}</v-chip
        >
      </v-card-text>
    </v-card>
  </v-container>
</template>
<script>
import { cleanUpSpecialChars } from "common/utils/people.js";
import PersonName from "common/components/PersonName.vue";
import PortraitImage from "common/components/PortraitImage.vue";
export default {
  name: "TeacherCodes",
  components: { PersonName, PortraitImage },
  data() {
    return {
      loading: false,
      found: false,
      firstName: "",
      lastName: "",
      code: "",
      codes: [],
      items: [],
      result: [],
      rules: [
        (v) => v.length == 3 || "genau 3 Zeichen",
        (v) => /[a-z|A-Z]{3}/.test(v) || "keine Sonderzeichen",
        (v) => /[a-z]{3}/.test(v) || "nur Kleinbuchstaben",
      ],
    };
  },
  methods: {
    reset() {
      this.codes = [];
      this.items = [];
    },
    async generateCodes() {
      if (this.firstName.length > 0 && this.lastName.length > 0) {
        this.reset();
        let i = 1;
        const firstNameClean = cleanUpSpecialChars(this.firstName);
        const lastNameClean = cleanUpSpecialChars(this.lastName);
        while (i < lastNameClean.length) {
          let generatedCode =
            lastNameClean.charAt(0).toLowerCase() +
            lastNameClean.charAt(i).toLowerCase() +
            firstNameClean.charAt(0).toLowerCase();
          let result = await this.apiList({
            resource: "person/person",
            query: `code=${generatedCode}`,
          });
          if (
            result.length > 0 &&
            this.items.map((item) => item.id).indexOf(result[0].id) < 0
          ) {
            this.items.push(result[0]);
          }
          if (result.length == 0 && this.codes.indexOf(generatedCode) < 0) {
            this.codes.push(generatedCode);
          }
          i++;
        }
      }
    },
    async checkCode() {
      if (this.code.length == 3) {
        let result = await this.apiList({
          resource: "person/person",
          query: `code=${this.code}`,
        });
        if (
          result.length > 0 &&
          this.items.map((item) => item.id).indexOf(result[0].id) < 0
        ) {
          this.items.push(result[0]);
        }
        if (result.length == 0 && this.codes.indexOf(this.code) < 0) {
          this.codes.push(this.code);
        }
      }
    },
  },
};
</script>
<style scoped>
.v-chip .v-avatar {
  height: 56px !important;
  min-width: 56px !important;
  width: 56px !important;
}
</style>
